// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-coaching-js": () => import("./../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-coaching-lopecoaching-js": () => import("./../src/pages/coaching/lopecoaching.js" /* webpackChunkName: "component---src-pages-coaching-lopecoaching-js" */),
  "component---src-pages-coaching-sykkelcoaching-js": () => import("./../src/pages/coaching/sykkelcoaching.js" /* webpackChunkName: "component---src-pages-coaching-sykkelcoaching-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-oss-js": () => import("./../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-om-oss-lopetreneren-js": () => import("./../src/pages/om-oss/lopetreneren.js" /* webpackChunkName: "component---src-pages-om-oss-lopetreneren-js" */),
  "component---src-pages-om-oss-sykkeltreneren-js": () => import("./../src/pages/om-oss/sykkeltreneren.js" /* webpackChunkName: "component---src-pages-om-oss-sykkeltreneren-js" */),
  "component---src-pages-omtaler-js": () => import("./../src/pages/omtaler.js" /* webpackChunkName: "component---src-pages-omtaler-js" */),
  "component---src-pages-priser-js": () => import("./../src/pages/priser.js" /* webpackChunkName: "component---src-pages-priser-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-takk-js": () => import("./../src/pages/takk.js" /* webpackChunkName: "component---src-pages-takk-js" */)
}

